.img {
    display: flex;
    flex-direction: column;
    width: 200;
    height: 300;
}
.summary, .headline{
    width: 900px;
}
.headline{
    font-family: 'Times Classic Bold';
    font-weight: 400;
    line-height: 1.125;
    font-size: 2.05rem;
    margin-bottom: 20px;
    color: #333;
}
.container{
    text-align: left;
    justify-content: left;
    display: table;
    margin: 1rem;
    padding: 10px;
    color: black;
    background-color: rgb(255, 255, 255);
  
    /* border-bottom: 1px solid rgb(78, 76, 76); */

    border-radius: 4px;
    box-shadow: 0 6px 10px rgba(0,0,0,0.08), 0 0 6px rgba(0,0,0,0.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
}
.container:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
.distributedDate{
    text-align: left;
    font-size: 10px;
    width:  200px;
    color:#6b6e74;
}
.page{
    margin: 0 auto;
    font-size: x-large;
}
.page>ul, .page>ul>li{
    text-align: center;
}
.page-item.active .page-link {
    border-color:#277f9c; 
    background-color:  #277f9c;
}
.page-link {
    color:  #277f9c;
}