.container{
    text-align: left;
    width: 900px;
}
.headline,.org,.date,.img-container{
    margin-bottom: 20px;
    color: #333;
}
.headline{
    font: 400 calc(16px + 1.5rem)/1.3 "Eva Bold","Times New Roman",Times,serif;
}
.org{
    color: #333;
    font-family: 'Times Classic Text';
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 1.25rem;
    border-bottom: 1px solid rgb(78, 76, 76);
}
.date {
    color: #333;
    font-family: 'Times Classic Text';
    font-size: 1.25rem;
}
.content {
   background-color: transparent;
   margin-top: 40px;
   /* font : normal 18px/1.5 "Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif ; */
}
.relId{
    opacity: 0.7;
    margin-top: 40px;
    text-align: right;
}
.img-container,.img-caption{
    text-align: center;
    font-style:italic;
    font-size: small;
}
@media (max-width: 500px) {
    .container {
        width:  330px;
        margin: auto 50px;
        text-align: left;
    }
    .img-container img{
        max-width: 100%;
    }
}