
.main {
    margin: 20px auto;
    width: 100%;
}

.searchcontainer{
    width: 490px;
    display: block;
    margin: 0 auto;
  }
  
  input#searchbar{
    margin: 0 auto;
    width: 100%;
    height: 45px;
    padding: 0 20px;
    font-size: 1rem;
    border: 1px solid #D0CFCE;
    outline: none;
   }
   input#searchbar:focus{
      border: 1px solid #008ABF;
      transition: 0.35s ease;
      color: #008ABF; 
     }
  
  #filtersubmit {
    position: relative;
    z-index: 1;
    left: -25px;
    top: -30px;
    color: #7B7B7B;
    cursor:pointer;
    display: inline;
    float: right;
    /* width: 0; */
}
#filtersubmit:hover{
    color: #008ABF; 
}
#clearSearch{
    margin-left: 20px;
    cursor:pointer;
    position: relative;
    z-index: 1;
    left: 55px;
    top: -37px;
    color: #7B7B7B;
    display: inline;
    float: right;
}
#clearSearch:hover{
    color: #df2020;
}