
.release{
    /* background-color: #6b6e74; */
    width:  350px;
    margin-left: 35px;
    /* border: 1px solid rgb(22, 18, 18); */
    float:left; 
    overflow: hidden;
    margin-bottom: auto;
}
@media (max-width: 500px) {
    .release {
        width:  250px;
        margin-left: 50px;
    }
}
.headline{
    color: black;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 5px;
    font: 400 16px/1.3 "Eva Bold","Times New Roman",Times,serif;
}
.summary{
    margin-bottom: 20px;
    color:#6b6e74;
    text-align: left;
    font : normal 14px/1.5 "Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif ;
}
.distributedDate{
    text-align: left;
    font-size: 10px;
    color:#6b6e74;
}

.article {
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.article:last-child { border-bottom: none; }
@media (max-width: 500px) {
    .release {
        width:  270px;
        margin: auto 60px;
    }
    .headline .summary .distributedDate{
        margin: auto 0px ;
        width: 270px;
        text-align: left;
    }
}