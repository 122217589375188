
.release {
    /* background-color: #6b6e74; */
    width:  240px;
    height: 380px;
    /* border: 1px solid rgb(22, 18, 18); */
    float:left; 
    overflow: hidden;
    margin-bottom: 30px;
}
@media (max-width: 500px) {
    .release {
        width:  270px;
        margin-left: 35px;
    }
}
@media (max-width: 500px) {
    .thumnailContainer, .distributedDate, div.headline, .summary{
        margin: 0px !important;
    }
}
.thumbnail {
    width:  200px;
    float:none; 
}
.thumnailContainer {
    width:  200px;
    height: 150px;
    overflow: hidden;
    margin: auto;
}
div.headline {
    color: black;
    text-align: left;
    width: 200px;
    margin: 10px auto;
    font: 400 16px/1.3 "Eva Bold","Times New Roman",Times,serif;
}
a:hover{
    text-decoration: none;
}
.summary {
    margin-bottom: 20px;
    color:#6b6e74;
    text-align: left;
    margin: auto;
    width:  200px;
    font : normal 14px/1.5 "Helvetica Neue",HelveticaNeue,Helvetica,Arial,sans-serif ;
}
.distributedDate{
    text-align: left;
    margin: 5px auto;
    font-size: 10px;
    width:  200px;
    color:#6b6e74;
}

.article {
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.article:last-child { border-bottom: none; }

 input {
    width: 100px;
}
.searchlist {
    width: 940px;
    justify-content: center;
    margin: 0 auto;
}
.page {
    align-items: center;
    justify-content: center;
    justify-self:  center;
    margin-top: 30px ;
    margin-left: auto ;
    margin-right: auto ;
}

@media  (max-width: 500px) {
    .release {
        width:  270px;
        margin: auto 50px ;
    }
}
@media (max-width: 500px) {
    .thumnailContainer, .distributedDate, div.headline, .summary, .thumbnail{
        
        margin: auto 0px ;
        width: 270px;
        text-align: left;
    }
}
